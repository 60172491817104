import { Button } from 'views/design-system';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useToastSaurus } from 'services/app/hooks';
import {
    AvancarIcon,
    TelefoneIcon,
    NomeIcon,
    CalendarioIcon,
    SexoIcon,
    FecharIcon,
    VoltarIcon,
} from 'views/components/icons';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumTelaIdentificacao } from 'model/enums/enum-aba-tela-identificacao';
import { useFormStepper } from 'views/components/form-stepper';
import { CpfCnpjTeclado } from './cpfcnpj-teclado/cpfcnpj-teclado';
import { FormStep } from 'model/app';
import { stringNumeros } from 'utils/string-numeros';
import { isEmpty, isObject } from 'lodash';
import { EnumSexo } from 'model/enums/enum-sexo';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { TelefoneTeclado } from './telefone-teclado/telefone-teclado';
import { DataNascimentoTeclado } from './data-nascimento-teclado/data-nascimento-teclado';
import { FormNomePreCadastro } from './nome-identificacao/nome-identificacao';
import { DefaultFormRefs } from 'views/components/form/utils';
import { IdentificacaoNomePreCadastroFormModel } from 'model/app/forms/mov-identificacao/identificacao-nome-pre-cadastro-form-model';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { SexoIdentificacao } from './sexo-identificacao/sexo-identificacao';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { toDateString } from 'utils/to-date';
import { maskDataNasc } from 'views/components/controles/inputs/text-field-saurus/text-field-saurus-masks';
import { useThemeQueries } from 'views/theme';
import { EnumMovModelo } from 'model';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { SugestaoSources, useClienteIdentificacaoUtils } from './use-cliente-identificacao-utils';
import { useLocation } from 'react-router-dom';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';
import { ConvenioCartaoAutenticarModel } from 'model/api/gestao/convenio';
import { useMovRota } from 'services/app/hooks/mov-rota';
import useEventListener from '@use-it/event-listener';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumKey } from 'model/enums/enum-keycode';


export interface ClienteIdentificacaoProps {
    redirecionarVenda: () => void;
}
interface LocationProps {
    convenio?: {
        modoPagamento: MovSimplesPagamentoModel
        credenciais: CredenciamentoSafra
        credenciado: boolean
        loginConvenio?: ConvenioCartaoAutenticarModel
    }
}

export const useClienteIdentificacao = ({ redirecionarVenda }: ClienteIdentificacaoProps) => {
    const { showToast } = useToastSaurus();
    const { currentStep, nextStep, prevStep } = useFormStepper(5);
    const { showSuggestionToast } = useClienteIdentificacaoUtils()
    const {
        getMov,
        isClienteCadastradoObrigatorio,
        setClienteByDoc,
        setClientePessoa,
        setTelefoneCliente,
        setDocumentoNaNota,
        carregando: carregandoMov,
        currentClienteIsPadrao
    } = useMovAtual()
    const { getConfigByCod } = useEmpresaAtual()
    const solicitarCliente = getConfigByCod(EnumEmpresaConfig.CamposSolicitarCliente)
    const cpfCNPJ = useRef<string>('');
    const nome = useRef<string>('');
    const telefone = useRef<string>('');
    const dtNasc = useRef<string>('')
    const sexo = useRef<EnumSexo>(EnumSexo.NaoInformado)
    const cpfCnpjNotaFiscal = useRef<boolean>(true)
    const qtdEtapas = useRef<number>(0)
    const cardSelectedIndex = useRef<number>(-1);
    const carregando = carregandoMov
    const { getConsumidor } = useCadastroPadrao()
    const { isMobile } = useThemeQueries()
    const { state } = useLocation<LocationProps | undefined>()
    const { redirectProcessarPagamento } = useMovRota()
    const { addHandler, removeHandler } = useEventTools();

    const soliClientesPorModeloTrabalho = useCallback(() => {
        return Number(solicitarCliente)
    }, [solicitarCliente])

    const steps = [
        EnumTelaIdentificacao.CpfCnpj,
        EnumTelaIdentificacao.Telefone,
        EnumTelaIdentificacao.Nome,
        EnumTelaIdentificacao.DataNascimento,
        EnumTelaIdentificacao.Sexo
    ].filter(step => Boolean(step & soliClientesPorModeloTrabalho()))

    const retTelaIndex = useCallback(
        (index: number): EnumTelaIdentificacao | undefined => {

            if (steps.length <= 0) {
                steps.push(EnumTelaIdentificacao.CpfCnpj)
            }

            let stepObj = {}

            steps.forEach((step, index) => {
                stepObj = {
                    ...stepObj,
                    [index]: step
                }
            })

            const retornaStepObj = (index: number) => ({
                ...stepObj
            })[index] || undefined

            return retornaStepObj(index)

        }, [steps],
    );


    const setupPreCadastroNomeForm = useCallback(async () => {
        let nameToSet = ''
        let fonteSugestao: SugestaoSources = 'Vazio'
        const mov = getMov()
        if (mov) {
            const isPadrao = await currentClienteIsPadrao()
            const clientePadrao = await getConsumidor();
            const cliente = mov.cliente
            if (cliente) {
                if (!(isPadrao && clientePadrao?.nome === cliente.nome)) {
                    nameToSet = cliente.nome
                    fonteSugestao = 'Fluxo de venda atual'
                }
            } else {
                const receitas = mov.receitasMedicas
                if (receitas.length > 0) {
                    const receita = receitas[0]
                    nameToSet = receita.comprador.nome
                    fonteSugestao = 'Receita'
                }
            }
            if (!isEmpty(nameToSet)) {
                refPreCadastroNome.current?.fillForm({
                    nome: nameToSet
                })
                showSuggestionToast({
                    key: 'Nome',
                    source: fonteSugestao
                })
            }
        }
    }, [currentClienteIsPadrao, getConsumidor, getMov, showSuggestionToast])

    useEffect(() => {
        switch (retTelaIndex(currentStep)) {
            case EnumTelaIdentificacao.Nome:
                setupPreCadastroNomeForm()
                break;
        }
    }, [
        currentStep,
        setupPreCadastroNomeForm,
        retTelaIndex
    ])

    useEffect(() => {
        const setIndex = (index: number) => { cardSelectedIndex.current = index };
        addHandler(AppEventEnum.KeyNavigationIndex, setIndex);
        return () => {
            removeHandler(AppEventEnum.KeyNavigationIndex, setIndex);
        }
    }, [addHandler, removeHandler])

    const refPreCadastroNome =
        useRef<DefaultFormRefs<IdentificacaoNomePreCadastroFormModel>>(null);



    const handleSubmitIdentificacao = useCallback(async (step: EnumTelaIdentificacao, lastEtapa: boolean) => {
        const mov = getMov()
        const consumidorPadrao = await getConsumidor()
        if (!mov || isEmpty(mov)) {
            return
        }
        const cliente = mov.cliente
        if (!cliente || isEmpty(cliente)) {
            return
        }

        const handleCpfCnpj = async () => {
            if (mov.mod === EnumMovModelo.NFE && stringNumeros(cpfCNPJ.current) === consumidorPadrao?.cpfcnpj) {
                throw new Error('O cliente deve ser diferente do consumidor padrão.')
            }

            if (cpfCNPJ.current.length <= 0) {
                throw new Error("CPF/CNPJ Obrigatório")
            }

            if (!validarCPFCNPJ(stringNumeros(cpfCNPJ.current))) {
                throw new Error("CPF/CNPJ inválido")
            }
            let fillWithClientePadrao = false

            if (!mov.cliente?.cpfcnpj || mov?.cliente?.cpfcnpj === consumidorPadrao?.cpfcnpj) {
                fillWithClientePadrao = false
            } else if (cpfCNPJ.current !== mov.cliente.cpfcnpj) {
                fillWithClientePadrao = true
            }

            const res = await setClienteByDoc(stringNumeros(cpfCNPJ.current), lastEtapa, fillWithClientePadrao)
            await setDocumentoNaNota(cpfCnpjNotaFiscal.current)
            if (isObject(res)) {
                showToast('success', 'Cadastro encontrado!')
                if (state?.convenio) {
                    await redirectProcessarPagamento({
                        ...state.convenio,
                        loginConvenio: {
                            ...state.convenio.loginConvenio,
                            pessoaId: res.id
                        }
                    })
                    return true
                }
                redirecionarVenda()
                return true
            }
        }

        const handleTelefone = async () => {
            const apenasNumeros = stringNumeros(telefone.current)
            if (
                apenasNumeros.length < 10) {
                throw new Error('Telefone inválido')
            }
            await setTelefoneCliente(stringNumeros(telefone.current), cliente, lastEtapa)
        }

        const handleDataNascimento = async () => {
            if (stringNumeros(dtNasc.current).length < 8) {
                throw new Error('Data de Nascimento inválida')
            }
            const date = maskDataNasc(dtNasc.current);
            const dateNasc = toDateString(date, 'yyyy-MM-DD') || ''
            await setClientePessoa({
                ...cliente,
                dtNasc: dateNasc
            }, lastEtapa)
        }

        const handleSexo = async () => {
            if (sexo.current === EnumSexo.NaoInformado) {
                throw new Error('Selecione uma das opções')
            }
            await setClientePessoa({
                ...cliente,
                sexo: sexo.current
            }, lastEtapa)
        }

        switch (step) {
            case EnumTelaIdentificacao.CpfCnpj:
                const res = await handleCpfCnpj()
                if (res) {
                    return true
                }
                break;
            case EnumTelaIdentificacao.Telefone:
                await handleTelefone()
                break;
            case EnumTelaIdentificacao.Nome:
                refPreCadastroNome.current?.submitForm()
                break;
            case EnumTelaIdentificacao.DataNascimento:
                await handleDataNascimento()
                break;
            case EnumTelaIdentificacao.Sexo:
                await handleSexo()
                break;
        }
    }, [getConsumidor, getMov, redirecionarVenda, redirectProcessarPagamento, setClienteByDoc, setClientePessoa, setDocumentoNaNota, setTelefoneCliente, showToast, state?.convenio]);

    const handleSubmitNaoIdentificar = useCallback(async (step: EnumTelaIdentificacao, lastEtapa: boolean) => {
        const mov = getMov()
        if (!mov || isEmpty(mov)) {
            return
        }
        const cliente = mov.cliente
        if (!cliente || isEmpty(cliente)) {
            return
        }

        const handleCpfCnpj = async () => {
            await setClienteByDoc('', lastEtapa, false)
            await setDocumentoNaNota(false)
        }

        const handleTelefone = async () => {
            await setTelefoneCliente('', cliente, lastEtapa)
        }

        const handleDataNascimento = async () => {
            await setClientePessoa({
                ...cliente,
                dtNasc: ''
            }, lastEtapa)
        }

        const handleNome = async () => {
            await setClientePessoa({
                ...cliente,
                nome: '',
                fantasia: ''
            }, lastEtapa)
        }

        const handleSexo = async () => {
            await setClientePessoa({
                ...cliente,
                sexo: EnumSexo.NaoInformado
            }, lastEtapa)
        }

        switch (step) {
            case EnumTelaIdentificacao.CpfCnpj:
                await handleCpfCnpj()
                break;
            case EnumTelaIdentificacao.Telefone:
                await handleTelefone()
                break;
            case EnumTelaIdentificacao.Nome:
                await handleNome()
                break;
            case EnumTelaIdentificacao.DataNascimento:
                await handleDataNascimento()
                break;
            case EnumTelaIdentificacao.Sexo:
                await handleSexo()
                break;
            case EnumTelaIdentificacao.Todos:
                await Promise.all([handleCpfCnpj(), handleTelefone(), handleNome(), handleDataNascimento(), handleSexo()])
                    .then(() => {
                        redirecionarVenda();
                    })
                    .catch((e) => {
                        showToast('error', e.message)
                    })
        }
    }, [getMov, redirecionarVenda, setClienteByDoc, setClientePessoa, setDocumentoNaNota, setTelefoneCliente, showToast]);

    const getFormPreCadastroCpfCnpj = useCallback((): JSX.Element => {
        const textChanged = async (text: string, formattedText: string) => {
            if (text.length > 14) {
                return true
            }
            cpfCNPJ.current = text;
            return true;
        }

        const checkboxValue = (value: boolean) => {
            cpfCnpjNotaFiscal.current = value
        }

        return (
            <CpfCnpjTeclado pequeno={steps.length > 1}  {...{ textChanged, checkboxValue, getMov }} />
        );
    }, [getMov, steps.length]);

    const getFormPreCadastroTelefone = useCallback((): JSX.Element => {
        const textChanged = async (text: string, formattedText: string) => {
            telefone.current = text;
            return true;
        }
        return (
            <TelefoneTeclado pequeno={steps.length > 1}  {...{ textChanged }} />
        );
    }, [steps.length]);

    const getFormPreCadastroNome = useCallback((): JSX.Element => {

        const handleSubmitForm = async (
            modelo: IdentificacaoNomePreCadastroFormModel,
        ) => {
            try {
                if (!modelo.nome) {
                    throw new Error('Informe o nome do cliente')
                }
                const mov = getMov()
                if (!mov || isEmpty(mov)) {
                    return
                }

                const cliente = mov.cliente
                if (!cliente || isEmpty(cliente)) {
                    return
                }
                await setClientePessoa({
                    ...cliente,
                    nome: modelo.nome,
                    fantasia: modelo.nome
                })
                if (currentStep < (qtdEtapas.current - 1)) {
                    nextStep()
                    return
                }
                redirecionarVenda()
            } catch (error: any) {
                showToast('error', error.message)
            }
        };
        return (
            <FormNomePreCadastro
                nomeRef={nome}
                ref={refPreCadastroNome}
                loading={false}
                onSubmit={handleSubmitForm}
                showLoading={false}
            />
        );
    }, [currentStep, getMov, nextStep, redirecionarVenda, setClientePessoa, showToast]);

    const getFormPreCadastroDataNascimento = useCallback((): JSX.Element => {
        const textChanged = async (text: string) => {
            dtNasc.current = text;
            return true;
        }
        return (
            <DataNascimentoTeclado pequeno={steps.length > 1}  {...{ textChanged }} />
        );
    }, [steps.length]);

    const getFormPreCadastroSexo = useCallback((): JSX.Element => {
        const valueChanged = async (text: EnumSexo) => {
            sexo.current = text;
            return true;
        }
        return (
            <SexoIdentificacao sexo={getMov()?.cliente?.sexo ?? 0} valueChanged={valueChanged} />
        );
    }, [getMov]);



    const avancarStep = useCallback(async (lastEtapa: boolean = false) => {
        try {
            const currentEtapaEnum = retTelaIndex(currentStep)
            if (currentEtapaEnum) {
                if (currentEtapaEnum === EnumTelaIdentificacao.CpfCnpj) {
                    const res = await handleSubmitIdentificacao(EnumTelaIdentificacao.CpfCnpj, lastEtapa);
                    if (res) {
                        return
                    }
                } else {
                    await handleSubmitIdentificacao(currentEtapaEnum, lastEtapa);
                }
            }

            if (currentEtapaEnum === EnumTelaIdentificacao.Nome) {
                return
            }
            if (!lastEtapa) {
                nextStep()
                return
            }
            redirecionarVenda()
        } catch (error: any) {
            showToast('error', error.message)
        }
    }, [currentStep, handleSubmitIdentificacao, nextStep, redirecionarVenda, retTelaIndex, showToast]);

    const naoInformarStep = useCallback(async (lastEtapa: boolean = false) => {
        try {
            await handleSubmitNaoIdentificar(retTelaIndex(currentStep) ?? EnumTelaIdentificacao.CpfCnpj, lastEtapa);

            if (!lastEtapa) {
                nextStep()
                return
            }
            redirecionarVenda()
        } catch (error: any) {
            showToast('error', error.message)
        }
    }, [currentStep, handleSubmitNaoIdentificar, nextStep, redirecionarVenda, retTelaIndex, showToast]);

    const validarAvanco = useCallback(async (lastEtapa: boolean = false) => {
        switch (retTelaIndex(currentStep)) {
            case EnumTelaIdentificacao.CpfCnpj:
                if (cpfCNPJ.current.length < 1) {
                    await naoInformarStep(lastEtapa);
                    return;
                }
                break;
            case EnumTelaIdentificacao.Nome:
                if (nome.current.length < 1) {
                    await naoInformarStep(lastEtapa);
                    return
                }
                break;
            case EnumTelaIdentificacao.DataNascimento:
                if (dtNasc.current.length < 1) {
                    await naoInformarStep(lastEtapa);
                    return
                }
                break;
            case EnumTelaIdentificacao.Sexo:
                if (!sexo.current) {
                    await naoInformarStep(lastEtapa);
                    return
                }
                break;
            case EnumTelaIdentificacao.Telefone:
                if (telefone.current.length < 1) {
                    await naoInformarStep(lastEtapa);
                    return
                }
                break;
        }
        avancarStep(lastEtapa);
    }, [avancarStep, currentStep, naoInformarStep, retTelaIndex])

    const voltarButton = useCallback((lastEtapa: boolean = false, primeiraEtapa: boolean = false) => {
        console.log('primeira', primeiraEtapa)
        return (
            <Button
                type="submit"
                color="primary"
                variant="outlined"
                rounded
                fullWidth
                onClick={async () => {
                    if (!primeiraEtapa) {
                        prevStep();
                        return
                    }
                    await handleSubmitNaoIdentificar(EnumTelaIdentificacao.Todos, true);
                }}
            >
                {primeiraEtapa ? <FecharIcon tipo="BUTTON" /> : <VoltarIcon tipo='BUTTON' />}
                {primeiraEtapa ? 'Não Informar' : 'Voltar'}
            </Button>
        );
    }, [handleSubmitNaoIdentificar, prevStep]);

    const avancarButton = useCallback((lastEtapa: boolean = false) => {
        return (
            <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                rounded
                onClick={() => validarAvanco(lastEtapa)}
            >
                {lastEtapa ? (
                    <>
                        <ConfirmarIcon tipo="BUTTON_PRIMARY" />
                        Confirmar
                    </>
                ) : (
                    <>
                        <AvancarIcon tipo="BUTTON_PRIMARY" />
                        Avançar
                    </>
                )}
            </Button>
        );
    }, [validarAvanco]);


    //VAI OBRIGAR O CARA A INFORMAR OS DADOS DO CLIENTE SE FOR NFE OU SE TIVER CONFIGURADO PARA PEDIDO
    const identificacaoObrigatoria = isClienteCadastradoObrigatorio();

    const getFormArray = useMemo(() => {
        const ret = [];

        let i = 0;
        while (retTelaIndex(i)) {
            const tela = retTelaIndex(i);
            switch (tela) {
                case EnumTelaIdentificacao.CpfCnpj:
                    ret.push(
                        new FormStep(
                            'CPF/CNPJ',
                            `Informe o CPF ou CNPJ`,
                            <PessoaIcon tipo="GERAL" />,
                            'CPF/CNPJ',
                            <PessoaIcon tipo="GERAL" />,
                            getFormPreCadastroCpfCnpj(),
                            !identificacaoObrigatoria ? voltarButton(false, true) : undefined,
                            avancarButton(),
                        ),
                    );
                    break;
                case EnumTelaIdentificacao.Telefone:
                    ret.push(
                        new FormStep(
                            'Telefone',
                            `Informe o Telefone`,
                            <TelefoneIcon tipo="GERAL" />,
                            'Telefone',
                            <TelefoneIcon tipo="GERAL" />,
                            getFormPreCadastroTelefone(),
                            !identificacaoObrigatoria ? voltarButton() : undefined,
                            avancarButton(),
                        ),
                    );
                    break;
                case EnumTelaIdentificacao.Nome:
                    ret.push(
                        new FormStep(
                            'Nome',
                            `Informe o Nome`,
                            <NomeIcon tipo="GERAL" />,
                            'Nome',
                            <NomeIcon tipo="GERAL" />,
                            getFormPreCadastroNome(),
                            !identificacaoObrigatoria ? voltarButton() : undefined,
                            avancarButton(),
                        ),
                    );
                    break;
                case EnumTelaIdentificacao.DataNascimento:
                    ret.push(
                        new FormStep(
                            'Data de Nascimento',
                            `Informe a Data de Nascimento`,
                            <CalendarioIcon tipo="GERAL" />,
                            isMobile ? 'Data' : 'Data de Nascimento',
                            <CalendarioIcon tipo="GERAL" />,
                            getFormPreCadastroDataNascimento(),
                            !identificacaoObrigatoria ? voltarButton() : undefined,
                            avancarButton(),
                        ),
                    );
                    break;
                case EnumTelaIdentificacao.Sexo:
                    ret.push(
                        new FormStep(
                            'Gênero',
                            `Informe o Gênero`,
                            <SexoIcon tipo="GERAL" />,
                            'Gênero',
                            <SexoIcon tipo="GERAL" />,
                            getFormPreCadastroSexo(),
                            !identificacaoObrigatoria ? voltarButton() : undefined,
                            avancarButton(),
                        ),
                    );
                    break;
            }
            i++;
        }
        if (ret.length > 0) {
            ret[ret.length - 1].previousButton = !identificacaoObrigatoria ? voltarButton(true, ret.length === 1) : undefined;
            ret[ret.length - 1].nextButton = avancarButton(true);
        }
        qtdEtapas.current = ret.length

        return ret;
    }, [retTelaIndex, getFormPreCadastroCpfCnpj, identificacaoObrigatoria, voltarButton, avancarButton, getFormPreCadastroTelefone, getFormPreCadastroNome, isMobile, getFormPreCadastroDataNascimento, getFormPreCadastroSexo]);

    useEventListener('keydown', (event: any) => {
        if (cardSelectedIndex.current === -1) {
            if (event.key === EnumKey.ENTER) {
                const isLast = qtdEtapas.current - 1 === currentStep;
                validarAvanco(isLast);
            }
        }
    })

    return {
        formStepper: {
            currentStep,
            nextStep,
            prevStep,
        },
        formArray: getFormArray,
        carregando: carregando
    };
};
