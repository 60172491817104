import { tpComissionadoRotaMock } from 'data/mocks/tp-comissionado-rota-mock';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTpPessoaComissionamento } from 'model/enums/enum-tp-pessoa-comissionamento';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { consoleDev } from 'utils/console-dev';
import { ComissaoComissionadosModal } from 'views/components/modals/comissao-comissionados/comissao-comissionados-modal';

export const CadastroComissionadosModalRender = () => {
  const { push, location } = useHistory();
  const { addHandler, removeHandler } = useEventTools();
  const [modalState, setModalState] = useState({
    id: '',
    comissaoId: '',
    tpComissionado: EnumTpPessoaComissionamento.Gerente,
    openned: false,
    ultimaUrl: '',
    search: ''
  });

  const comissaoRoute = `/comissoes/`;
  const comissionadoRoute = `/comissionado-`;

  consoleDev('CadastroComissionadosModal');

  useEffect(() => {
    if (
      location.pathname.indexOf(comissaoRoute) === 0 &&
      location.pathname.indexOf(comissionadoRoute) === 47
    ) {
      try {
        const rota = location.pathname.split('/');

        const comissaoId = rota[2];
        const tipo = rota[3].replace('comissionado-', '');

        const tipoEnum =
          tpComissionadoRotaMock.find((x) => x.Value === tipo)?.Key ??
          EnumTpPessoaComissionamento.Gerente;

        if (comissaoId.length > 0) {
          setModalState((prev) => ({
            id: '',
            comissaoId,
            openned: true,
            tpComissionado: tipoEnum,
            ultimaUrl: `${rota[0]}/${rota[1]}/${rota[2]}`,
            search: prev.search
          }));
          return;
        }
      } catch {}
    }
  }, [location.pathname, comissaoRoute, comissionadoRoute]);
  console.log(modalState);
  const modalAlterado = useCallback(
    ({ comissaoId, tpComissionado, openned, callbackUrl, trocarUrl }: any) => {
      const tipoRota = tpComissionadoRotaMock.find(
        (x) => x.Key === tpComissionado
      )?.Value;
      if (tipoRota === undefined && openned)
        throw new Error('Não existe este tipo de comissionado.');

      let url = comissaoRoute + comissaoId + comissionadoRoute + tipoRota;
      if (callbackUrl.length > 0) {
        url = callbackUrl;
      }

      if (modalState.openned) {
        url = modalState.ultimaUrl;
      }
      const prevSearch = modalState.search;
      setModalState({
        id: '',
        comissaoId,
        tpComissionado,
        openned: openned,
        ultimaUrl: url,
        search: window.location.search
      });
      if (!openned && !trocarUrl) {
        push({
          pathname: url,
          search: prevSearch
        });
      }
      if (trocarUrl) {
        push(url);
      }
    },
    [
      comissaoRoute,
      comissionadoRoute,
      modalState.openned,
      modalState.search,
      modalState.ultimaUrl,
      push
    ]
  );

  useEffect(() => {
    addHandler(AppEventEnum.ComissaoComissionadosModal, modalAlterado);
    return () => {
      removeHandler(AppEventEnum.ComissaoComissionadosModal, modalAlterado);
    };
  }, [addHandler, modalAlterado, removeHandler]);

  return (
    <>
      <ComissaoComissionadosModal
        openned={modalState.openned}
        key="mdlComissionados"
        id={modalState.id}
        comissaoId={modalState.comissaoId}
        tpComissionado={modalState.tpComissionado}
      />
    </>
  );
};
