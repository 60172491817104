import { useEffect, useMemo } from 'react';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { CarrinhoContainer } from './components/cart-wrapper/carrinho-container';
import { Box, Grid } from 'views/design-system';
import { CircularLoading } from 'views/components';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useFaturamento } from 'services/app/hooks/faturamento';
import classNames from 'classnames';
import { useImagemWhitelabel } from 'services/app/hooks/imagem-whitelabel';
import { useStyles } from './mov-carrinho-page-styles'
import { useDesktopMode } from 'services/app/hooks/desktop-mode';
import { PesquisaProduto } from '../components/pesquisa/produtos/pesquisa-produtos/pesquisa-produto';
import { PublicPageHeader } from 'views/components/headers';
import { useSessaoAtual } from 'services/app';
import UsuarioSemImagem from 'assets/img/usuario-sem-imagem.png';

export const MovCarrinhoPage = () => {
  // STATES E REFS

  // PROVIDERS & HOOKS
  const classes = useDefaultCadastroStyles();
  const pageClasses = useStyles();
  const { faturarVenda, carregandoFaturamento } = useFaturamento();
  const { imagemWhitelabel } = useImagemWhitelabel();
  const { getDesktopMode } = useDesktopMode();
  const carregando = carregandoFaturamento;
  const { usuario } = useSessaoAtual();

  useEffect(() => {
    faturarVenda();
  }, [faturarVenda]);

  const setHeaderProps = useVendaHeaderStore((state) => state.setHeaderProps);

  useEffect(() => {
    setHeaderProps({
      title: {
        mobile: 'Carrinho',
        desktop: 'Digite ou Passe o Produto'
      },
      showDefinicoes: true,
      showMenuPdv: true,
      pesquisa: {
        allowLetras: true,
        allowNumbers: true,
        capturarDigitacao: true
      },
    })
  }, [setHeaderProps]);

  const isDesktopMode = getDesktopMode();

  const imagemCarrinho = useMemo(() => {
    if (usuario?.imagem) {
      return {
        src: usuario?.imagem,
        alt: 'Logo do usuário'
      }
    } else if (imagemWhitelabel) {
      return {
        src: imagemWhitelabel,
        alt: 'Logo do Whitelabel'
      }
    } else {
      return {
        src: UsuarioSemImagem,
        alt: 'Sem imagem'
      }
    }
  }, [imagemWhitelabel, usuario?.imagem])

  return (
    <>
      <Grid className={classes.root}>
        {carregando ?
          (
            <CircularLoading tipo="FULLSIZED" />
          )
          : (
            <>
              <Box className={pageClasses.pagesContainer}>
                {isDesktopMode && <Box className={classes.halfPage}>
                  <Box className={pageClasses.logoContainer}>
                    <img
                      src={imagemCarrinho.src}
                      alt={imagemCarrinho.alt}
                      className={pageClasses.logo}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = UsuarioSemImagem;
                      }}
                    />
                  </Box>
                  <PesquisaProduto />
                  <PublicPageHeader containedPage />
                </Box>}
                <Box className={isDesktopMode ? classNames(classes.cartPage, classes.divisoria) : classes.root}>
                  <CarrinhoContainer isPageCarrinho />
                  {!isDesktopMode && <PesquisaProduto />}
                </Box>
              </Box>
            </>
          )}
      </Grid>
    </>
  );
};